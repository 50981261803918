import * as React from "react"
import { Button, AnFadeIn } from "components"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "utils/linkResolver"
import { RichText } from "prismic-reactjs"
import { Swiper, SwiperSlide } from "swiper/react"
import {
  carouselClass,
  carouselItem,
  carouselImage,
  yellSection,
  yellBottom,
} from "./styles.module.css"
import { navigate } from "gatsby-link"
import { Helmet } from "react-helmet"

const AttitudePage = ({ data }) => {
  const { carousel, title, title_text } = data.allPrismicAttitude.nodes[0].data
  return (
    <AnFadeIn>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Still Curious | Attitude`}</title>
      </Helmet>
      <section className={yellSection}>
        <h1>{title.text}</h1>
        <RichText render={title_text.raw} />
      </section>

      <Swiper
        className={carouselClass}
        slidesPerView={1}
        slidesPerGroup={1}
        lazy={true}
        speed={1500}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          800: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            speed: 1750,
          },
        }}
      >
        {[
          ...carousel,
          ...carousel,
          ...carousel,
          ...carousel,
          ...carousel,
          ...carousel,
          ...carousel,
        ].map((image, index) => (
          <SwiperSlide
            key={`${image.carousel_images.url}-${index}`}
            className={carouselItem}
          >
            <img
              className={carouselImage}
              src={image.carousel_images.url}
              alt={image.carousel_images.alt}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <section className={yellBottom}>
        <Button title="Contact Us" onClick={() => navigate("/contact")} />
      </section>
    </AnFadeIn>
  )
}

export const query = graphql`
  query AttitudeQuery {
    allPrismicAttitude {
      nodes {
        data {
          carousel {
            carousel_images {
              url
              alt
            }
          }
          title {
            text
          }
          title_text {
            raw
          }
        }
      }
    }
  }
`

export default withPrismicPreview(AttitudePage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
